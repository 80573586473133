import { ordersQueryDocument } from 'api/graphql';
import { OrderStatus, OrderType } from 'api/graphql/generated/graphql';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { LISTING_RECORDS_PER_PAGE, MAX_FILTERING_DAYS } from 'pages/platform/constants';
import { useCallback, useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useQuery } from 'urql';

import { getInitialListingPageState } from './initialStates';
// import fixturesData from './__fixtures__.json';
import UnpaidRequestsPage from './partials/UnpaidRequestsPage';
import { IListingPagePropsObj, IVirtualFilterParentObj, IVirtualFilterValuesObj, OrdersQueryEdges } from './types';

const getFilterInitialState = () => {
  return {
    statusName: '',
    startDate: '',
    endDate: '',
    filterPopupOpen: false,
    isFilterRequest: false,
    merchantId: '',
    orderId: '',
    txnId: '',
    amount: '',
  };
};

function VirtualTreminalPage() {
  const [listingPageProps, setListingPageProps] = useState<IListingPagePropsObj>(getInitialListingPageState());

  const [filterObj, setFilterObj] = useState<IVirtualFilterParentObj>(getFilterInitialState());

  const { first, last, before, after } = useMemo(() => listingPageProps, [listingPageProps]);

  const limit = useMemo(() => first ?? last ?? LISTING_RECORDS_PER_PAGE, [first, last]);

  const [{ fetching, data, error }] = useQuery({
    query: ordersQueryDocument,
    variables: {
      last,
      before,
      first,
      after,
      orderType: OrderType.PayByLink,
      status: [OrderStatus.Created, OrderStatus.Cancelled],
    },
  });

  const handleSort = useCallback(
    (selectedColumn: TableColumn<OrdersQueryEdges[number]>, sortDirection: SortOrder) => {},
    [],
  );

  const filterRecordHandler = (obj: IVirtualFilterValuesObj) => {
    let fromDate = '';
    let toDate = '';

    if (obj.dateTypeCompareValue) {
      if (obj.dateTypeCompareValue === 'is_in_the_last') {
        if (obj.dateTypeFilterValue === 'days') {
          fromDate = `${DateTime.utc()
            .minus({ days: parseInt(obj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }

        if (obj.dateTypeFilterValue === 'month') {
          fromDate = `${DateTime.utc()
            .minus({ months: parseInt(obj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }
      }

      if (obj.dateTypeCompareValue === 'is_equal_to' && obj.dateSingleValue) {
        const fromDateObj = new Date(obj.dateSingleValue);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
      }

      if (obj.dateTypeCompareValue === 'is_between' && obj.dateRangeValue) {
        const fromDateObj = new Date(obj.dateRangeValue[0]);
        const toDateObj = new Date(obj.dateRangeValue[1]);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (obj.dateTypeCompareValue === 'is_after' && obj.dateSingleValue) {
        const fromDateObj = new Date(obj.dateSingleValue);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .plus({ months: MAX_FILTERING_DAYS })
          .endOf('day')
          .toISO()}`;
      }

      if (obj.dateTypeCompareValue === 'is_before' && obj.dateSingleValue) {
        const fromDateObj = new Date(obj.dateSingleValue);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .minus({ months: MAX_FILTERING_DAYS })
          .startOf('day')
          .toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }
    }

    setFilterObj((prevState) => ({
      ...prevState,
      statusName: obj.status,
      startDate: encodeURIComponent(fromDate),
      endDate: encodeURIComponent(toDate),
      isFilterRequest: true,
      merchantId: obj.merchantId,
      orderId: obj.orderId,
      txnId: obj.txnId,
      amount: obj.amount,
    }));
  };

  const toggleFilter = useCallback(() => {
    setFilterObj((prevState) => ({
      ...prevState,
      filterPopupOpen: !prevState.filterPopupOpen,
    }));
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!data?.orders) return;

      const isFirst = page === 1;
      const isPrev = !isFirst && page === listingPageProps.page - 1;
      const isNext = page === listingPageProps.page + 1;
      const isLast = !isNext && page === Math.ceil(data.orders.totalCount / limit);

      setListingPageProps({
        ...listingPageProps,
        page,
        last: isLast || isPrev ? limit : null,
        before: !isLast && isPrev ? data.orders.pageInfo.startCursor ?? null : null,
        first: isFirst || isNext ? limit : null,
        after: !isFirst && isNext ? data.orders.pageInfo.endCursor ?? null : null,
      });
    },
    [data?.orders, limit, listingPageProps],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage: number, page: number) => {
      setListingPageProps({
        ...listingPageProps,
        page,
        last: listingPageProps.last !== null ? newPerPage : null,
        first: listingPageProps.first !== null ? newPerPage : null,
      });
    },
    [listingPageProps],
  );

  return (
    <MerchantDashboardLayout activeTab="virtual-terminal" title="Virtual Terminal">
      {!!error && <ErrorComponent bodyText={error.message} />}
      <UnpaidRequestsPage
        virtualTerminalList={data?.orders?.edges ?? []}
        fetchListLoader={fetching}
        totalRows={data?.orders?.totalCount ?? 0}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        limit={limit}
        toggleFilter={toggleFilter}
        filterRecordHandler={filterRecordHandler}
        filterValuesObj={filterObj}
        activePage={listingPageProps.page}
        handleSort={handleSort}
      />
    </MerchantDashboardLayout>
  );
}

export default VirtualTreminalPage;

import * as s from 'assets/styles/Platforms.styles';
import { useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';

import { Button, FlexContainer, Heading, Icon, Text } from '@limepayments/cosmic';

import { IVirtualFilterObj, IVirtualFilterParentObj, IVirtualFilterValuesObj, OrdersQueryEdges } from '../types';
import CreateNewOrderModal from './CreateNewOrderModal';
// Import Static UIs
import FilterComponent from './FilterComponent';
import UnpaidRequestsDataTable from './UnpaidRequestsDataTable';

const getInitialFilterState = () => {
  return {
    dateValue: '',
    dateTypeCompareValue: '',
    dateTypeFilterValue: '',
    statusValue: '',
    dateRangeValue: '',
    dateSingleValue: null,
    saveClick: false,
    dateActive: false,
    statusFilterApply: false,
    merchantIdSelected: false,
    txnIdSelected: false,
    orderIdSelected: false,
    amountIdSelected: false,
    merchantId: '',
    orderId: '',
    txnId: '',
    amount: '',
    customerNameSelected: false,
    customerName: '',
    paymentOptionSelected: false,
    paymentOption: '',
  };
};

export interface Props {
  virtualTerminalList: OrdersQueryEdges;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: () => void;
  filterRecordHandler: (obj: IVirtualFilterValuesObj) => void;
  filterValuesObj: IVirtualFilterParentObj;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<OrdersQueryEdges[number]>, sortDirection: SortOrder) => void;
  isFilterRequest?: boolean;
  isErrorRequest?: boolean;
  createOrderModalOpen?: boolean;
}

function UnpaidRequestsPage({
  virtualTerminalList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  toggleFilter,
  filterRecordHandler,
  filterValuesObj,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
  createOrderModalOpen,
}: Props) {
  // filter section variables
  const [filterValueObj] = useState<IVirtualFilterObj>(getInitialFilterState());
  const [createNewOrderModalOpen, setCreateNewOrderModalOpen] = useState<boolean>(createOrderModalOpen ?? false);

  const getFilterApplyCount = () => {
    let returnCount = 0;

    if (filterValueObj.dateActive && filterValueObj.saveClick) {
      returnCount++;
    }

    if (filterValueObj.statusFilterApply && filterValueObj.saveClick) {
      returnCount++;
    }

    if (filterValueObj.merchantIdSelected && filterValueObj.merchantId) {
      returnCount++;
    }

    if (filterValueObj.orderIdSelected && filterValueObj.orderId) {
      returnCount++;
    }

    if (filterValueObj.txnIdSelected && filterValueObj.txnId) {
      returnCount++;
    }

    if (filterValueObj.amountIdSelected && filterValueObj.amount) {
      returnCount++;
    }

    return returnCount;
  };

  return (
    <div className="content-wrap-inner lp-full m-0">
      <s.WellWrap className="">
        <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-0 lp-align-end">
          <div className="lp-flex lp-flex-col">
            <Heading alignment="left" tagName="h2" variant="md" className="page-title mb-0">
              Virtual terminal
            </Heading>
            <Text alignment="left" tagName="p" variant="body-2">
              Create an order on behalf of your customer.
            </Text>
          </div>
          <FlexContainer classNames="lp-flex lp-flex-end sm:lp-full sm:mt-16">
            <div className="lp-flex order-2 position-relative">
              <Button
                className="lp-w-full"
                size="medium"
                variant="primary"
                onClick={() => setCreateNewOrderModalOpen(!createNewOrderModalOpen)}
              >
                Create new order
                <Icon name="Plus" className="ml-16" />
              </Button>
            </div>
          </FlexContainer>
        </div>
      </s.WellWrap>

      <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-0">
        <div className="lp-flex lp-align-center">
          <Heading alignment="left" tagName="h2" variant="xs">
            Unpaid requests
          </Heading>
          <span
            className="icon-16px ml-16px"
            role="button"
            data-balloon="Order details will move to the Payments tab after a full or partial payment has been made."
            data-balloon-pos="up"
            data-balloon-mobile-pos="up"
            data-balloon-length="large"
            data-balloon-mobile-length="medium"
          >
            <Icon name="Info" className="" />
          </span>
        </div>
        <div className="merchant-controls sm-mt-16 pr-0">
          <FlexContainer classNames="lp-flex m-0">
            <div className="lp-flex order-2 position-relative m-0">
              <Button
                className="lp-w-full"
                size="small"
                variant="reversed"
                onClick={() => toggleFilter()}
                disabled={virtualTerminalList.length < 1 && getFilterApplyCount() === 0}
              >
                <Icon name="Filter" className="ml-0 mr-16" />
                Filter ({getFilterApplyCount()})
              </Button>

              {/* Filter Dropdown */}
              {filterValuesObj.filterPopupOpen && (
                <div className="filter-dropdown w-328">
                  <FilterComponent toggleFilter={toggleFilter} />
                </div>
              )}
            </div>
          </FlexContainer>
        </div>
      </div>

      <UnpaidRequestsDataTable
        virtualTerminalList={virtualTerminalList}
        fetchListLoader={fetchListLoader}
        totalRows={totalRows}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        limit={limit}
        activePage={activePage}
        handleSort={handleSort}
        isFilterRequest={isFilterRequest || getFilterApplyCount() > 0}
        isErrorRequest={isErrorRequest}
        setCreateNewOrderModalOpen={setCreateNewOrderModalOpen}
        toggleFilter={toggleFilter}
      />

      <CreateNewOrderModal isOpen={createNewOrderModalOpen} modalToggler={setCreateNewOrderModalOpen} />
    </div>
  );
}

export default UnpaidRequestsPage;

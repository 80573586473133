import { OrderStatus } from 'api/graphql/generated/graphql';

import { TagProps } from '@limepayments/cosmic/build/Tag/Tag';

interface IVirtualTerminalStatusObj {
  [status: string]: {
    label: string;
    varient: TagProps['variant'];
    tooltipText: string;
  };
}

export const VirtualTerminalStatusObj: IVirtualTerminalStatusObj = {
  created: {
    label: 'Created',
    varient: 'warning',
    tooltipText:
      'This payment request has not been paid by the customer. It will move to the Payments tab after a full or partial payment has been made.',
  },
  cancelled: {
    label: 'Cancelled',
    varient: 'neutral',
    tooltipText: 'This payment request has been cancelled.',
  },
};

export const toVirtualTerminalStatusObj = (status: OrderStatus) => VirtualTerminalStatusObj[status.toLowerCase()] ?? {};

export const VirtualTerminalRoutesList = {
  VIRTUAL_TERMINAL_LANDING: 'all',
};

export default VirtualTerminalRoutesList;

import { Client, cacheExchange, fetchExchange } from 'urql';
import { getAprilApiHost } from 'utils/env';
import getCurrentUserToken from 'utils/getCurrentUserToken';

import { authExchange } from '@urql/exchange-auth';

import { graphql } from './generated/gql';

export const createUrqlClient = async () =>
  new Client({
    url: `${await getAprilApiHost()}/graphql`,
    requestPolicy: 'cache-and-network',
    exchanges: [
      cacheExchange,
      authExchange(async (utils) => {
        let token = await getCurrentUserToken();

        return {
          addAuthToOperation(operation) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
          didAuthError(error, operation) {
            return error.response.status === 401;
          },
          async refreshAuth() {
            token = await getCurrentUserToken();
          },
        };
      }),
      fetchExchange,
    ],
  });

export const ordersQueryDocument = graphql(`
  query ordersQuery(
    $last: Int
    $before: String
    $first: Int
    $after: String
    $amount: AmountFilterInput
    $status: [OrderStatus!]
    $orderIds: [String!]
    $orderType: [OrderType!]
    $createdAt: InstantFilterInput
  ) {
    orders(
      last: $last
      before: $before
      first: $first
      after: $after
      amount: $amount
      status: $status
      orderIds: $orderIds
      orderType: $orderType
      createdAt: $createdAt
    ) {
      edges {
        node {
          amount {
            minorCurrencyUnits
            currency
          }
          createdAt
          status
          description
          customerName
          orderType
          referenceOrderId
          orderId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`);

export const transactionsQueryDocument = graphql(`
  query transactionsQuery(
    $last: Int
    $before: String
    $first: Int
    $after: String
    $amount: AmountFilterInput
    $status: [TransactionStatus!]
    $customerIds: [String!]
    $transactionIds: [String!]
    $orderIds: [String!]
    $payType: PayType
    $createdAt: InstantFilterInput
    $availableOn: LocalDateFilterInput
    $orderBy: [TransactionOrderInput!]
  ) {
    transactions(
      last: $last
      before: $before
      first: $first
      after: $after
      amount: $amount
      status: $status
      customerIds: $customerIds
      transactionIds: $transactionIds
      orderIds: $orderIds
      payType: $payType
      createdAt: $createdAt
      availableOn: $availableOn
      orderBy: $orderBy
    ) {
      edges {
        node {
          amount {
            minorCurrencyUnits
            currency
          }
          availableOn
          createdAt
          status
          customer {
            customerName
          }
          payType
          order {
            referenceOrderId
            orderId
          }
          transactionId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`);

export const payoutsQueryDocument = graphql(`
  query payoutsQuery(
    $last: Int
    $before: String
    $first: Int
    $after: String
    $amount: AmountFilterInput
    $status: [PayoutStatus!]
    $payoutIds: [String!]
    $createdAt: InstantFilterInput
    $settlementDate: LocalDateFilterInput
  ) {
    payouts(
      last: $last
      before: $before
      first: $first
      after: $after
      amount: $amount
      status: $status
      payoutIds: $payoutIds
      createdAt: $createdAt
      settlementDate: $settlementDate
    ) {
      edges {
        node {
          amount {
            minorCurrencyUnits
            currency
          }
          payoutId
          status
          createdAt
          settlementDate
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`);

export const searchCustomersQueryDocument = graphql(`
  query searchCustomersQuery($merchantIds: [String!]!, $query: String!, $first: Int = 10) {
    search {
      customers(merchantIds: $merchantIds, query: $query, first: $first) {
        customerId
        referenceCustomerId
        customerEmailAddress
        customerName
      }
    }
  }
`);

export const searchOrdersQueryDocument = graphql(`
  query searchOrdersQuery($merchantIds: [String!]!, $query: String!, $first: Int = 10) {
    search {
      orders(merchantIds: $merchantIds, query: $query, first: $first) {
        orderId
        referenceOrderId
      }
    }
  }
`);

export const searchTransactionsQueryDocument = graphql(`
  query searchTransactionsQuery($merchantIds: [String!]!, $query: String!, $first: Int = 10) {
    search {
      transactions(merchantIds: $merchantIds, query: $query, first: $first) {
        transactionId
      }
    }
  }
`);

export const searchPayoutsQueryDocument = graphql(`
  query searchPayoutsQuery($merchantIds: [String!]!, $query: String!, $first: Int = 10) {
    search {
      payouts(merchantIds: $merchantIds, query: $query, first: $first) {
        payoutId
      }
    }
  }
`);

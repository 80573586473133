import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

import { IListingPagePropsObj } from './types';

export const getInitialListingPageState = (): IListingPagePropsObj => {
  return {
    page: 1,
    last: null,
    before: null,
    first: LISTING_RECORDS_PER_PAGE,
    after: null,
  };
};
